const isCodigoFixoCarrefour = (location: Location) => {
  const urlParams = new URLSearchParams(location.search);
  const regex = /#[a-zA-Z0-9]{4}-[a-zA-Z0-9]{3,4}-[a-zA-Z0-9]{3}/;

  return Object.keys(urlParams).length === 0 && regex.test(location.hash);
};

const getLegadoUrl = (location: Location) => {
  const navigationParameter = isCodigoFixoCarrefour(location) ? location.hash : location.search;
  return `http://conecta-legado.paripassu.com.br${navigationParameter}`;
};

export {
  isCodigoFixoCarrefour,
  getLegadoUrl,
};
