
// https://m3.material.io/styles/color/the-color-system/tokens#e26e130c-fa67-48e1-81ca-d28f6e4ed398

/**
 * Primary is used here as an example:
 * @Primary base color
 * @OnPrimary is applied to content (icons, text, etc.) that sits on top of primary
 * @PrimaryContainer container is applied to elements needing less emphasis than primary
 * @OnPrimaryContainer is applied to content (icons, text, etc.) that sits on top of primary container
 * Neutral roles are used for surfaces and backgrounds, as well as high emphasis text and icons.
 */
export const Color = {
  Primary: '#0C44B3',
  OnPrimary: '#FFFFFF',
  PrimaryContainer: '#EAEAEA',
  OnPrimaryContainer: '#0C44B3',

  Secondary: '#8CAF47',
  OnSecondary: '#FFFFFF',
  SecondaryContainer: '#f1f1f1',
  OnSecondaryContainer: '#B9CCF5',
  OnSecondaryTransparent: '#3377FF66',

  Tertiary: '#E86146',
  OnTertiary: '#FFFFFF',
  TertiaryContainer: '#F8E1E9',
  OnTertiaryContainer: '#370B1E',

  Error: '#B3261E',
  OnError: '#FFFFFF',
  ErrorContainer: '#F9DEDC',
  OnErrorContainer: '#410E0B',

  // Neutral Key Color
  Background: '#FFFBFE',
  OnBackground: '#1C1B1F',
  Surface: '#FFFBFE',
  OnSurface: '#1C1B1F',

  // Neutral Variant Key Color
  SurfaceVariant: '#E7E0EC',
  OnSurfaceVariant: '#49454E',
  Outline: '#79747E',
  OutlineVariant: '#C4C7C5',

  Shadow: '#000000',
  SurfaceTint: '#0C44B3',
  InverseSurface: '#313033',
  InverseOnSurface: '#F4EFF4',
  InversePrimary: '#B9CCF5',
  Scrim: '#000000',

  // Text
  text01: '#455069',
  text02: '#7C8599',
  text03: '#B8BECC',
  text04: '#CBD1E0',

  //azulPari: '#3377FF',
  // azulEscuro: '#0C44B3',
  // verdeClaro: '#D3FF78',
  LightGreen: '#E8EFDA',
  LightOrange: '#F5E1DB',
  LightYellow: '#ECBE0725',
  Gray: '#5E5E5E',
  CardBackground: '#F8F8F8FF',
  // verdeEscuro: '#83B320',
  // laranja: '#FF6245',
  // cinzaFundo: '#F1F1F1'
  // Amarelo: 'ECBE07FF'
};
