import React from 'react';
import { Icon } from '@mdi/react';
import {
  mdiFoodAppleOutline,
  mdiLightbulbOnOutline,
  mdiSproutOutline,
} from '@mdi/js';
import { Color } from '../../assets/theme/color';
import { getLegadoUrl } from '../../core/UrlManager';
import { logoPoweredBy, logoConectaSlogan } from '../../assets';
import './HomePage.css';

export const HomePage = () => {
  const screenWidth = window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth;
  const screenHeight = window.innerHeight
    || document.documentElement.clientHeight
    || document.body.clientHeight;

  const handleNavigateToConectaLegado = () => {
    window.location.href = getLegadoUrl(window.location);
  };

  const handleNavigateToDownloadApple = () => {
    window.location.href = 'https://apps.apple.com/br/app/conecta-paripassu-2-0/id6460169232';
  };

  const handleNavigateToDownloadGooglePlay = () => {
    window.location.href = 'https://play.google.com/store/apps/details?id=br.com.paripassu.conectaapp';
  };

  return (
    <div
      className="container"
      style={{
        width: screenWidth,
        height: screenHeight,
        backgroundColor: Color.Primary,
      }}
    >
      <div className="logo-conecta">
        <img
          src={logoConectaSlogan}
          alt="logo Conecta PariPassu - Conect-se com o seu alimento"
          width="100%"
        />
      </div>

      <div className='center'>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: 24,
          }}
        >
          <label style={{ display: 'flex', alignItems: 'center' }}>
            <Icon path={mdiSproutOutline} size={1} style={{ marginRight: 8 }} />
            Conheça a origem do seu alimento
          </label>

          <label style={{ display: 'flex', alignItems: 'center' }}>
            <Icon
              path={mdiFoodAppleOutline}
              size={1}
              style={{ marginRight: 8 }}
            />
            Aproveite as dicas e o guia de sazonalidade
          </label>

          <label
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              textAlign: 'center',
            }}
          >
            <Icon
              path={mdiLightbulbOnOutline}
              size={1}
              style={{ marginRight: 8 }}
            />
            Surpreenda-se com a infinidade de receitas
            <br />
            geradas pela nossa Inteligência Artificial
          </label>
        </div>

        <h3 style={{ marginTop: 40, marginBottom: 8 }}>Baixar aplicativo</h3>

        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <button
            className="btn-download-app"
            onClick={handleNavigateToDownloadGooglePlay}
            style={{ backgroundColor: Color.Secondary, marginRight: 16 }}
          >
            Android
          </button>
          <button
            className="btn-download-app"
            onClick={handleNavigateToDownloadApple}
            style={{ backgroundColor: Color.Secondary }}
          >
            iOS
          </button>
        </div>

        <div className='center'>
          <label style={{ marginTop: 8 }}>ou</label>

          <button
            className="btn-old-version"
            onClick={handleNavigateToConectaLegado}
            style={{}}
          >
            Ir para versão antiga
          </button>
        </div>
      </div>

      <div className="logo-footer">
        <img src={logoPoweredBy} alt='logo powered by PariPassu' />
      </div>
    </div>
  );
};
